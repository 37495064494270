import m       from "mithril";
import { sub } from "template";

import globalState from "../../../../state";

import featureCheck from "../../../../components/feature-check";

import content from "./components/content";

import css from "./purchase-history.css";

const legalAddress = "NC Interactive, LLC | 3180 139th Avenue SE, #500 | Bellevue, WA 98005";

export default featureCheck("purchaseHistory", {
    view() {
        const gcI18n = globalState.i18n.purchaseHistory;

        return m("div.pure-u-1", { class : css.bd },

            m("h3", gcI18n.title),

            // purchase history/orders table, buysite buttons, errors, etc
            m(content),

            // legal
            m("div", { class : css.legal },
                gcI18n.legal.map(l =>
                    m("p",
                        m.trust(sub(l, {
                            faqUrl : globalState.urls.purchaseSupport
                        }))
                    )
                ),
                m("p", legalAddress)
            )
        );
    }
});
