import m from "mithril";

import globalState  from "../../../state";
import featureCheck from "../../../components/feature-check";

import css from "../index.css";

export default featureCheck("gameFeatures", {
    view() {
        const gcI18n = globalState.i18n.gameContent;

        return m("div.pure-u-1", { class : css.featuresModule },

            m("h3", `${globalState.gameCode} ${gcI18n.features}`),
            m("table", { class : "table" },
                m("thead",
                    m("tr",
                        m("th", "id"),
                        m("th", "count"),
                        m("th", "max"),
                        m("th", "name")
                    )
                ),
                m("tbody",
                    globalState.accountFeatures.map(({ id, count, max_count, name }) =>
                        m("tr",
                            m("td", id),
                            m("td", count),
                            m("td", max_count),
                            m("td", name)
                        )
                    )
                )
            )
        );
    }
});
