import m from "mithril";

import globalState from "../state";

export default function(feature, component) {
    return {
        view(...args) {
            if (!globalState.features[feature]) {
                return null;
            }

            return m(component, ...args);
        }
    };
}
