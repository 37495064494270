import m from "mithril";

import { addCodeBtn, addCodePanel } from "./components/add-a-code";
import linkGw1Account               from "./components/link-gw1-account";
import features                     from "./components/features";
import games                        from "./components/games";
import purchaseHistory              from "./components/purchase-history/purchase-history";

import css from "./index.css";

export default {
    view() {
        return [
            m("div.pure-g",
                {
                    class       : css.gameContent,
                    "data-test" : "contentContainer"
                },

                m(addCodeBtn),

                m(games),

                m(purchaseHistory),

                m(features),

                m(addCodePanel),

                m(linkGw1Account)
            )
        ];
    }
};
