import m from "mithril";

import css from "./index.css";

export default {
    view({ attrs }) {
        const { fixed = true } = attrs;

        return m("div[data-test=loading]", { class : fixed ? css.loadingSpinnerFixed : css.loadingSpinner });
    }
};
