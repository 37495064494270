import m from "mithril";

import globalState from "../../../../../state";

import css from "./pagination.css";

const MAX_PAGES = 5;

function range(start, end) {
    return Array.from({ length : end - start }, (_, i) => i + start);
}

/**
 * Page numbers based on current page with more pages on either side
 * @param {Number} currentPage - current page, eg 4
 * @param {Number} totalItems - total number of items, 100
 * @param {Number} pagesize - page size, 10
 * @returns {Number[]} page numbers, eg [ 2, 3, 4, 5, 6 ]
 */
export function getPages(currentPage, totalItems, pagesize) {
    const middlePages = [];
    const totalPages  = Math.ceil(totalItems / pagesize);

    if (totalPages <= MAX_PAGES) {
        return range(1, totalPages + 1);
    }

    let iPage = currentPage;
    let idx   = 0;

    // keep adding numbers above and below until we have MAX_PAGES numbers
    while (middlePages.length < MAX_PAGES && idx < (MAX_PAGES * 2)) {
        if (iPage > 0 && iPage <= totalPages) {
            middlePages.push(iPage);
        }

        const delta = Math.floor(idx / 2) + 1;
        const dir   = idx % 2 === 0 ? -1 : 1;

        iPage = currentPage + (dir * delta);

        idx++;
    }

    return middlePages.sort((a, b) => Number(a) - Number(b));
}

/**
 * Pagination button
 * @param {Object} options
 * @param {String} [options.key] used for CSV and icon
 * @param {Number} options.page page number the button will load
 * @returns {Object} mithril button
 */
function button({ key, page, attrs }) {
    const gcI18n              = globalState.i18n.purchaseHistory;
    const { purchaseHistory } = globalState;

    // don't build these paths so hashing works
    const icons = {
        first    : "https://account.staticwars.com/pages/content/img/first.svg",
        previous : "https://account.staticwars.com/pages/content/img/previous.svg",
        next     : "https://account.staticwars.com/pages/content/img/next.svg",
        last     : "https://account.staticwars.com/pages/content/img/last.svg"
    };

    // pages are either a Number, or have a `key` for icons and locs

    return m("button", {
        "data-active" : !key && page === purchaseHistory.page,
        "data-page"   : page,
        "aria-label"  : gcI18n.pagination[key] || page,
        "data-test"   : `pagination-button-${key || page}`,

        title : gcI18n.pagination[key], // no title needed for numbers
        key   : key || page,

        class : css[key ? `pagination-${key}` : "pagination-page"],

        ...attrs,


        onclick(e) {
            e.preventDefault();

            if (page === purchaseHistory.page) {
                return;
            }

            purchaseHistory.getOrders({ page });
        }
    }, key ? m("img", { src : icons[key] }) : page);
}

export default {
    view() {
        const { purchaseHistory } = globalState;

        if (
            !purchaseHistory.total_count ||
            purchaseHistory.total_count <= purchaseHistory.page_size
        ) {
            return null;
        }

        const { total_count, page, page_size } = purchaseHistory;

        const rangeOfPages = getPages(page, total_count, page_size);
        const lastPage     = Math.ceil(total_count / page_size);

        const nextPage = page + 1 < lastPage ? page + 1 : lastPage;
        const prevPage = page - 1 > 0 ? page - 1 : 1;

        return m("nav", {
                class       : css.pagination,
                "data-test" : `purchase-history-pagination-${rangeOfPages.length}`
            },
            [{
                key   : "first",
                page  : 1,
                attrs : {
                    disabled : page === 1
                }
            }, {
                key   : "previous",
                page  : prevPage,
                attrs : {
                    disabled : page === prevPage
                }
            }].map(button),

            rangeOfPages.map(pageOfRange => button({ page : pageOfRange })),

            [{
                key   : "next",
                page  : page + 1 < lastPage ? page + 1 : lastPage,
                attrs : {
                    disabled : page === nextPage
                }
            }, {
                key   : "last",
                page  : lastPage,
                attrs : {
                    disabled : page === lastPage
                }
            }].map(button)
        );
    }
};
