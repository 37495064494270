import m from "mithril";

import { formatDate, formatMoney, formatTaxRate } from "./formatters";
import { convertToCSV }                           from "./convert-to-csv";

export const states = {
    INIT            : "init",
    LOADING         : "loading",
    LOADING_DETAILS : "loadingDetails",
    LOADED          : "loaded",
    ERROR           : "error",
    EMPTY           : "empty"
};

export const errorStates = {
    ORDERS  : "Error fetching orders",
    DETAILS : "Error fetching order details"
};

const DEFAULT_VALUES = {
    page      : undefined,
    page_size : 10,

    orders      : undefined,
    total_count : undefined,
    details     : undefined,
    csv         : undefined,

    errorState : undefined,
    error      : undefined
};

/** PurchaseHistory */
export class PurchaseHistory {
    #apiUrl;

    #orders_by_page = {};
    #details_by_order_key = {};

    /**
     * Create a new PurchaseHistory instance
     * @param {Object} args
     * @param {String} args.apiUrl
     */
    constructor({ apiUrl }) {
        Object.assign(this, DEFAULT_VALUES);

        this.#apiUrl = apiUrl;

        this.state = states.INIT;
    }

    /**
     * Handle getOrders errors
     * @param {Error} error
     */
    #handleOrdersError(error) {
        Object.assign(this, DEFAULT_VALUES);

        this.state      = states.ERROR;
        this.errorState = errorStates.ORDERS;
        this.error      = error;
    }

    /**
     * Handle getDetails errors
     * @param {Error} error
     */
    #handleDetailsError(error) {
        this.details = DEFAULT_VALUES.details;

        this.state      = states.ERROR;
        this.errorState = errorStates.DETAILS;
        this.error      = error;
    }

    /**
     * Update current orders
     * @param {Object} args
     * @param {Number} args.page
     * @param {Array} args.orders
     * @param {Number} args.total_count
     */
    #updateOrders(args = {}) {
        Object.assign(this, args);

        this.#orders_by_page[this.page] = this.orders;
        this.csv                        = convertToCSV(this.orders);

        this.state = this.total_count ? states.LOADED : states.EMPTY;
    }

    /**
     * Get orders from WAPI
     * @param {Object} args
     * @param {Number} args.page
     * @returns {Promise}
     * @throws {Error}
     */
    async getOrders({ page = 1 }) {
        if (this.page === page) {
            return;
        }


        // reset current orders
        this.page    = page;
        this.orders  = DEFAULT_VALUES.orders;
        this.csv     = DEFAULT_VALUES.csv;
        this.details = DEFAULT_VALUES.details;


        // check for cached orders
        const cachedOrder = this.#orders_by_page[page];

        if (cachedOrder) {
            this.#updateOrders({ page, orders : cachedOrder });

            return;
        }


        // need to fetch orders
        this.state = states.LOADING;

        // eslint-disable-next-line no-labels
        tryFetch: try {
            const response = await fetch(`/wapi/api/user/orders?page=${page}&format=json`, {
                method      : "GET",
                // include cookies
                credentials : "include"
            });

            // this is ok, player hasn't made any purchases
            if (response.status === 404) {
                this.#updateOrders({ page, orders : [], total_count : 0 });

                break tryFetch; // eslint-disable-line no-labels
            }

            const { wapi } = await response.json();

            const { orders, total_count } = wapi;

            orders.forEach(purchase => {
                purchase.registered  = formatDate(purchase.registered);
                purchase.grand_total = formatMoney(purchase.grand_total);
                purchase.sales_tax   = formatMoney(purchase.sales_tax);
            });

            this.#updateOrders({ page, orders, total_count });
        } catch (error) {
            this.#handleOrdersError(error);
        }

        m.redraw();
    }

    /**
     * Get details for a specific order
     * @param {String} order_key
     * @returns {Promise}
     * @throws {Error}
     */
    async getDetails(order_key) {
        // checked for cached details
        const cachedDetails = this.#details_by_order_key[order_key];

        if (cachedDetails) {
            this.details = cachedDetails;

            return;
        }

         // reset current details
        this.details = DEFAULT_VALUES.details;
        this.state   = states.LOADING_DETAILS;


        // need to fetch details
        try {
            const response = await fetch(`/wapi/api/user/orders/${order_key}?format=json`, {
                method      : "GET",
                // include cookies
                credentials : "include"
            });

            if (response.status === 404) {
                throw new Error("Order details not found");
            }

            const { wapi : details } = await response.json();

            details.grand_total          = formatMoney(details.grand_total);
            details.refund_total         = formatMoney(details.refund_total);
            details.subtotal             = formatMoney(details.subtotal);
            details.taxes.sales_tax      = formatMoney(details.taxes.sales_tax);
            details.taxes.sales_tax_rate = formatTaxRate(details.taxes.sales_tax_rate);

            details.line_items.forEach(lineItem => {
                lineItem.goods_base_price      = formatMoney(lineItem.goods_base_price);
                lineItem.goods_discount_amount = formatMoney(lineItem.goods_discount_amount);
                lineItem.goods_sale_price      = formatMoney(lineItem.goods_sale_price);
            });

            this.#details_by_order_key[order_key] = details;
            this.details                          = details;
            this.state                            = states.LOADED;
        } catch (error) {
            this.#handleDetailsError(error);
        }

        m.redraw();
    }
}
