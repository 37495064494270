import m         from "mithril";
import form      from "Form";
import { sub }   from "template";
import mailcheck from "mailcheck";

import schemas from "../../../../preload/schemas";

import passwordInputs from "../../../../components/password-inputs";
import formField      from "../../../../components/form-field";
import a              from "../../../../components/anchor";
import tooltip        from "../../../../components/tooltip";
import recaptcha      from "../../../../components/recaptcha";

import handleForm from "../../../../util/handle-form";
import gamesight  from "../../../../util/gamesight";

import productKeyField from "../product-key-field";

import globalState from "../../../../state";

import css from "../../index.css";

const formI18n     = globalState.i18n.forms.register;
const registerI18n = globalState.i18n.register;
const digitSchema  = {
    email       : schemas.register.email,
    displayname : schemas.register.displayname
};

export default {
    oninit({ state }) {
        let suffixTimeout;

        Object.assign(state, {
            displaynameSuffix : "####",
            emailSuggestion   : "",
            emailState        : {
                // user could fill in email last and hit enter
                // which circumvents the onblur
                // valid - mailinator?
                // recaptcha - matches pattern
                checked : false
            },

            fakeIp        : m.route.param("fakeip"),
            testRecaptcha : m.route.param("testRecaptcha"),

            fields : {
                email       : "",
                displayname : ""
            },

            getSuffix() {
                clearTimeout(suffixTimeout);

                if (!form.validate(state.fields, digitSchema).valid) {
                    return;
                }

                state.displaynameSuffix = "####";

                // debounce
                suffixTimeout = setTimeout(() => {
                    m.request({
                        url  : `/ws/username?${m.buildQueryString({ _t : Date.now() })}`,
                        data : state.fields
                    })
                    .then(({ suffix }) => {
                        state.displaynameSuffix = suffix;
                    });
                }, 200);
            }
        });
    },

    view({ state, attrs }) {
        const { regTheme } = attrs;

        state.queryParams = { alt : regTheme.name };

        // a little ugly, but want alt to always be first param for test selectors, and don't want a blank param
        if (state.fakeIp) {
            state.queryParams.fakeip = state.fakeIp;
        }

        if (state.testRecaptcha !== undefined) {
            state.queryParams.testRecaptcha = "";
        }

        return m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-10-24", {
                class : [ css.registerModule, css.regMod ].join(" ")
            },
            m("div.pure-u-1", { class : css.brandLogo }),

            m("form.pure-form[data-test=registerForm]",
                handleForm({
                    method : "post",
                    action : `/register?${m.buildQueryString(state.queryParams)}`,
                    class  : m.route.param("regType") ? css.emailPrompt : "",
                    onSuccess() {
                        gamesight("send", "account_anet_new_account_success");
                    }
                }),

                // After registration, this page is reloaded w/ regType param
                // If regType exists, show email auth
                m.route.param("regType") ?

                    // todo: should this be in the form? pull out into a separate component (without reg logic)?
                    m("div[data-test=registerSuccess]", { class : css.emailVerify },
                        m("h3", registerI18n["email-prompt"].title),

                        m("img", { src : "https://account.staticwars.com/pages/register/img/mail_icon.svg", alt : "" }),

                        m("h3", registerI18n["email-prompt"].subtitle),

                        m("p", registerI18n["email-prompt"].body)
                    ) :
                    [
                        m("h3.serif.pure-u-1", { class : css.formHeading },
                            m.trust(regTheme.i18n.title)
                        ),

                        // value of this input is used for determining the schema
                        m("input", {
                                type  : "hidden",
                                name  : "_formName",
                                value : globalState.features.captcha ? "register_captcha" : "register"
                            }
                        ),

                        // for newsletter signup
                        m(`input[type=hidden][name=url][value=${window.location.href.split("?")[0]}]`),

                        m(formField, {
                            name         : "email",
                            autocomplete : "email",
                            type         : "email",
                            labelClass   : "pure-u-1",
                            value        : state.email,
                            strings      : formI18n.email,
                            oninput      : m.withAttr("value", value => {
                                const suggestion = mailcheck.run({ email : value });

                                state.emailState.checked = false;
                                state.emailFocused       = true;
                                state.emailSuggestion    = suggestion ? suggestion.full : "";

                                state.fields.email = value;
                                state.getSuffix();
                            }),
                            oncreate({ dom }) {
                                dom.focus();
                            },
                            onblur() {
                                state.emailFocused = false;
                            }
                        }),

                        state.emailSuggestion && !state.emailFocused ?
                            m("div[data-test=emailSuggestion]", {
                                    class : css.suggestion,
                                    onclick() {
                                        state.email = state.emailSuggestion;
                                        delete state.emailSuggestion;
                                    }
                                },
                                m.trust(sub(formI18n.emailSuggestion, { email : state.emailSuggestion }))
                            ) :
                            null,

                        m(passwordInputs),

                        m(formField, {
                            name         : "displayname",
                            autocomplete : "username",
                            labelClass   : `pure-u-2-3 ${css.displayname}`,
                            maxlength    : 27,
                            value        : state.displayname,
                            strings      : formI18n.displayname,
                            oninput      : m.withAttr("value", value => {
                                state.fields.displayname = value;
                                state.getSuffix();
                            })
                        }),

                        m("div.pure-u", { class : css.digits },
                            `.${state.displaynameSuffix}`,

                            m("div", { class : css.help },
                                formI18n.displayname.digits,

                                m(tooltip, { style : css.tooltip },
                                    m.trust(formI18n.displayname["digits-help"])
                                )
                            )
                        ),

                        regTheme.p4f ?
                            // Why a noscript? page is JS only
                            m("noscript", m(productKeyField)) :
                            m(productKeyField),

                        globalState.china ?
                            null :
                            m(formField, {
                                name       : "newsletter",
                                labelClass : "pure-u-1 pure-checkbox",
                                type       : "checkbox",
                                value      : "on",
                                strings    : formI18n.newsletter
                            }),

                        m(formField, {
                            name       : "eula",
                            type       : "checkbox",
                            labelClass : "pure-u-1 pure-checkbox",
                            value      : "on",
                            strings    : formI18n.eula
                        }),

                        m(recaptcha),

                        m("button[type=submit].pure-button.pure-button-primary.pure-u-1",
                            formI18n.submit
                        ),

                        m("p.pure-u-1", { class : css.agree },
                            m.trust(formI18n.accept.label)
                        ),

                        m("div", { class : css.exLink },
                            `${formI18n["download-text"]} `,
                            m(a, { href : "/welcome" }, formI18n["download-link"])
                        ),

                        m("div", { class : css.exLink }, m.trust(regTheme.i18n.learn))
                    ]
            )
        );
    }
};
