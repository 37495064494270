import m          from "mithril";
import objectPath from "object-path";

import deepAssign from "./util/deep-assign";

import Loader              from "./util/loader";
import Form                from "./util/form";
import { PurchaseHistory } from "./util/purchase-history";

import modalController  from "./util/modal-controller";
import requestPageState from "./util/request-page";
import invalidateCookie from "./util/invalidate-cookie";
import getErrMsg        from "./util/get-err-msg";
import gamesight        from "./util/gamesight";

import isSteam from "isSteam";
import isEpic  from "isEpic";

class State {
    constructor({ strings, langs, lang, steam, urls, gameCode, redirect }) {
        this.i18n                = strings;
        this.lang                = lang || "en";
        this.langs               = langs || [ "en", "de", "es", "fr" ];
        this.gameCode            = gameCode;
        this.china               = gameCode === "gw2cn";
        this.loader              = new Loader();
        this.redirect            = redirect || "";
        this.modal               = modalController;
        this.steam               = steam;
        this.showMobileNav       = false;
        this.urls                = urls;
        this.twitch_id           = null;
        this.twitch_display_name = null;
        this.purchaseHistory     = null;

        this._loading        = false;
        this._page           = "";
        this._errorMessage   = "";
        this._containerClass = "";

        // form data container
        this.form = new Form();
    }

    get containerClass() {
        return this.page.replace(/\//g, "-");
    }

    set containerClass(containerClass) {
        this._containerClass = containerClass;
    }

    get loggedIn() {
        return this.get("user.user_id");
    }

    // show "upgrade" on base GW2 tile if missing any expansions
    get missingExpansions() {
        return Object.entries(this.gw2Features)
            .filter(([ key, value ]) => value.isExp && !value.owned)
            .map(([ key, value ]) => key);
    }

    twoStepUrl() {
        if (this.auth_type === "totp" || this.auth_type === "sms") {
            return `/security/2-step/${this.auth_type}/unlink/warning`;
        }

        return "/security/2-step";
    }

    update(newState = {}) {
        deepAssign(this, newState);
        this.setDefaults();
    }

    logout() {
        requestPageState("logout")
            .then(() => {
                gamesight("logout");

                this.user = { user_id : false };

                this.setDefaults();

                invalidateCookie("s");

                m.route.set("/login");
            });
    }

    /**
     * proxy loading back to Loader module
     * @param {object} opts - options passed through to loader.getState()
     */
    updateState(opts) {
        return this.loader.getState(this.page, this, opts);
    }

    preloadPages(background = true) {
        return this.loader.preloadPages(this.page, this.loggedIn, this, background);
    }

    invalidateLoaded() {
        this.loader.invalidateLoaded();
        this.preloadPages(false);
    }

    isPreloaded() {
        return this.loader.preloaded();
    }

    setDefaults() {
        this.hasGw1 = this.hasGw1 || false;
        this.hasGw2 = Boolean(this.gw2Features);

        this.gw2Features   = this.gw2Features || {};
        this.commPrefs     = this.commPrefs || {};
        this.appData       = this.appData || {};
        this.allowlist     = this.allowlist || [];
        this.logins        = this.logins || [];
        this.showMobileNav = false;

        this.twitch_id           = this.loggedIn && this.twitch_id || null;
        this.twitch_display_name = this.loggedIn && this.twitch_display_name || null;

        this.purchaseHistory = this.loggedIn &&
            this.purchaseHistory || null;

        this.theme = this.loggedIn && m.route.get() !== "/welcome" ?
            localStorage.getItem("theme") :
            "default";
    }

    /**
     * Check if account is 3p
     * @param {"Epic"|"Steam"} platform
     * @returns {boolean}
     */
    #isThirdParty(platform) {
        const loginName = this.get("user.login_name");

        if (loginName) {
            return platform === "Steam" ? isSteam(loginName) : isEpic(loginName);
        }

        return m.route.param("provider") === platform;
    }

    getBuyLink(game = "gw2") {
        let key;

        if (this.isSteamUser && game !== "gw1") {
            key = "steamBuyLinks";
        } else if (this.isEpicUser && game !== "gw1") {
            key = "epicBuyLinks";
        } else {
            key = "buyLinks";
        }

        return this.get([
            "urls",
            key,
            game
        ]) || this.buyLink;
    }

    get(...args) {
        return objectPath.get(this, ...args);
    }

    getAndSub(path, sub) {
        let str = objectPath.get(this, path);

        sub.forEach(s => {
            str = str.replace(`{${s}}`, objectPath.get(this, s));
        });

        return str;
    }

    get loading() {
        return this._loading || this.loader.loading;
    }

    // https://coryrylan.com/blog/javascript-es6-class-syntax
    set loading(loading) {
        this._loading = loading;
    }

    get page() {
        return location.pathname.substr(1);
    }

    set page(page) {
        this._page = page;
    }

    clearErrorMessage() {
        this.form.clearErrorMessage();
        this._errorMessage = "";
    }

    get errorMessage() {
        return this.form.errorMessage || this._errorMessage;
    }

    set errorMessage(errorMessage) {
        this._errorMessage = errorMessage;
    }

    get isSteamUser() {
        return this.#isThirdParty("Steam");
    }

    get isEpicUser() {
        return this.#isThirdParty("Epic");
    }

    get isThirdParty() {
        return this.isSteamUser || this.isEpicUser;
    }

    get provider() {
        if (this.isSteamUser) {
            return "Steam";
        }

        if (this.isEpicUser) {
            return "Epic";
        }

        return "Portal";
    }

    set theme(val) {
        if (this.loggedIn) {
            localStorage.setItem("theme", val);
        }

        document.body.setAttribute("data-theme", val || "");

        this._theme = val;
    }
    get theme() {
        return this._theme;
    }
}

const state = new State({
    strings  : window.strings,
    langs    : window.state.langs,
    lang     : document.documentElement.lang,
    urls     : window.state.urls,
    gameCode : window.state.gameCode,
    redirect : window.state.redirect // zendesk jwt login when already logged into account site
});

// can't move this into constructor, as getErrMsg() references state
// Steam logins might have errors on first load
if (window.state.error) {
    state.errorMessage = getErrMsg({ error : window.state.error });
}

export default state;
